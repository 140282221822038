import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import QRCode from "react-qr-code";
import api from "../../api/api";
import { useReactToPrint } from "react-to-print";
import Logo from "../../assets/images/logo-ciwa.png";
import useAdmission from "../../hooks/useAdmission";
import useDetails from "../../hooks/useDetails";

const ApprovedAdmissionLetter = () => {
  const { data, staging: stage, loading } = useAdmission();
  // const [details, setDetails] = useState(data?.admin_response)
  const details = data?.admin_response[0]?.approved_program

  console.log("admission details",details)

  const componentRef = useRef();

  const pageStyle = `
    @page {
      size: 210mm 297mm;
      margin: 0rem 1rem;
    }
  `;
  const printAcceptanceLetter = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "AdmissionLetter",
    onAfterPrint: () => console.log("Admission letter printed"),
    pageStyle: pageStyle,
  });

  return (
    <>
      {(stage === "approved" || stage === "null") && (
        <>
          <div className="" id="adm_letter" style={{ minHeight: "100vh" }}>
            <div className="">
              <div className="container-fluid">
                <div className="card" ref={componentRef}>
                  <div
                    className="body"
                    style={{ fontFamily: "serif", padding: "5rem 10rem" }}
                  >
                    <div
                      style={{
                        marginBottom: "2rem",
                        display: "flex",
                        justifyContent: "center",
                        gap: "4",
                      }}
                    >
                      <div style={{ height: "100px", width: "100px" }}>
                        <img
                          alt="logo"
                          src={Logo}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <h3
                        style={{
                          marginBottom: "0px",
                          textAlign: "center",
                          textTransform: "uppercase",
                          color: "#000",
                        }}
                      >
                        Catholic Institute of West Africa
                        <br /> Port Harcourt, Rivers State
                      </h3>
                    </div>
                    <div
                      style={{
                        borderBottom: "2px solid gray",
                        marginBottom: "2rem",
                      }}
                    ></div>
                    <div className="d-flex justify-content-between">
                      <div>
                        <h4
                          className="m-0"
                          style={{
                            fontFamily: "serif",
                            color: "#000",
                            fontStyle: "italic",
                          }}
                        >
                          Our Ref
                        </h4>
                        <h4 style={{ fontFamily: "serif", color: "#000" }}>
                          12/09/2023
                        </h4>
                      </div>
                      <div className="text-right">
                        <h4
                          className="m-0"
                          style={{ fontFamily: "serif", color: "#000" }}
                        >
                          OFFICE OF THE REGISTRAR
                        </h4>
                        <h4
                          className="m-0"
                          style={{ fontFamily: "serif", color: "#000" }}
                        >
                          +234
                        </h4>
                        <a
                          style={{ fontFamily: "serif" }}
                          href="#"
                          className="text-primary font"
                        >
                          info@ciwa.edu.ng
                        </a>
                        <h4
                          className="m-0"
                          style={{ fontFamily: "serif", color: "#000" }}
                        >
                          ciwa.edu.ng
                        </h4>
                      </div>
                    </div>
                    <h4 style={{ color: "#000", marginBottom: "3rem" }}>
                      Dear <b>{data?.surname + " " + data?.othername}</b>
                    </h4>
                    <div>
                      <h3
                        style={{
                          color: "#000",
                          textDecoration: "underline",
                          textTransform: "uppercase",
                        }}
                      >
                        OFFER OF PROVISIONAL ADMISSION TO A{" "}
                        {data?.programmeType} PROGRAMME IN {details?.department}
                      </h3>
                    </div>
                    <div style={{ marginBottom: "1rem" }}>
                      <p
                        style={{
                          fontFamily: "serif",
                          color: "#000",
                          fontSize: "16px",
                        }}
                      >
                        Following your application for admission and upon the
                        approval of the Admission Board, we are glad to inform
                        you that you have been offered provisional admission to{" "}
                        <b className="text-uppercase">
                          {data?.programmeType}. {details?.department}
                        </b>{" "}
                        Programme in the department of{" "}
                        <b className="text-uppercase">{details?.department}</b>{" "}
                        of the Catholic Institute of West Africa Port Harcourt,
                        Rivers State, for the <b>{data?.entrySession}</b>{" "}
                        academic session.
                        <br />
                        <br />
                        This offer of provisional admission is full-time and
                        will run for a period of <b>1</b> academic sessions.
                        <br />
                        <br />
                        Please note that Candidates for the Post-Graduate
                        Programme in Theology or related studies are expected to
                        take a course in NT Greek except they can show that they
                        are proficient in it already, and this has to be
                        verified by the Head of Department. Also candidates
                        admitted to the Programme, who cannot provide evidence
                        of having studied French, Latin, German or Italian
                        Languages to a minimum level of WAEC, GEC, are expected
                        to participate in the Language Programme offered by
                        during the Long Vacation every year. The language course
                        is a requirement for graduation.
                        <br />
                        <br />
                        Please note that the confirmation of this offer of
                        admission, apart from the payment of Acceptance Fess, is
                        subject to conditions spelt out below:
                        <br />
                        <br />
                        1. Payment of School Fees through the student portal
                        <br />
                        2. Automatic generation of Matriculation Number on the
                        student portal upon payment of School Fees
                        <br />
                        3. You are advised to regularly check the website
                        www.ciwa.edu.eng and your email account for further
                        information.
                        <br />
                        <br />
                        Kindly visit the website for guidelines in respect of
                        Matriculation, Orientation, Registration, Student
                        Handbook, Prospectus and eLibraries.
                        <br />
                        <br />
                        Accept my congratulations as I look forward to welcoming
                        you at the Matriculation Ceremony.
                        <br />
                        <br />
                        <br />
                      </p>
                      <h4 className="font-italic" style={{color:'#000', fontStyle:"italic"}}>Your faithfully,</h4>
                      <br />
                      <h4 className="font-italic font-weight-bold m-0" style={{color:'#000'}}>
                        Rev. Fr. Dr. Patrick O. Okuta
                      </h4>
                      <h4 className="font-italic m-0" style={{color:'#000'}}>Registrar</h4>
                      <br/>
                      <br/>
                      <div>
                        <h4 className="font-italic m-0" style={{color:'#000', fontStyle:"italic"}}><b>CC:</b> Reactor,</h4>
                        <h4 className="font-italic m-0" style={{color:'#000', fontStyle:"italic"}}>All Academic Deans</h4>
                        <h4 className="font-italic m-0" style={{color:'#000', fontStyle:"italic"}}>HoDs</h4>
                        <h4 className="font-italic m-0" style={{color:'#000', fontStyle:"italic"}}>Dean of Students' Affairs</h4>
                        <h4 className="font-italic m-0" style={{color:'#000', fontStyle:"italic"}}>Bursar</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <center>
                <button
                  className="btn btn-raised btn-primary waves-effect"
                  onClick={printAcceptanceLetter}
                >
                  Print Admission Letter
                </button>
              </center>
            </div>
          </div>
        </>

      )}</>
  );
};

export default ApprovedAdmissionLetter;
