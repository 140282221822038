import React, { useEffect, useRef, useState } from "react";
import {
  Navigate,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import logo from "../../assets/images/CIWA_logo.png";
import {
  getCurrentUser,
  removeUser,
} from "../../components/services/auth/authService";
// import "./Style.css"
import { useContext } from "react";
import { AlignRight, Home, LogOut, Menu } from "react-feather";
import { GetApplicantDetails, GetPaymentStatus } from "../../api/application";
import avatar from "../../assets/images/user.png";
import AuthContext from "../../context/AuthContext";
import useDetails from "../../hooks/useDetails";
import $ from 'jquery';
import 'metismenu';
import 'metismenu/dist/metisMenu.min.css';

function ApplicationWrapper() {
  const [transaction, setTransaction] = useState({});
  const [passport, setPassport] = useState();
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [details, setDetails] = useState();
  const [screenSize, setScreenSize] = useState(undefined)
  const menuRef = useRef(null);

  let navigate = useNavigate();
  const user = getCurrentUser();
  const {data, staging:stage} = useDetails()

  const token = localStorage.getItem("user-token");
  const location = useLocation();

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  useEffect(() => {
  
    const handleResize = () => setScreenSize(window.innerWidth)
  
    window.addEventListener('resize', handleResize)
  
    handleResize()
  
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  
  useEffect(()=> {
    let toggleAble = document.querySelector("body");
  
    if (screenSize <= 1024) {
      toggleAble.classList.add("enlarge-menu-all");
      toggleAble.classList.add("enlarge-menu");
    } else {
      toggleAble.classList.remove("enlarge-menu-all");
      toggleAble.classList.remove("enlarge-menu");
    }
  },[screenSize])

  // const getDetails = async () => {
  //   try {
  //     const { data } = await GetApplicantDetails(user.application_id);
  //     console.log("getting detais", data);
  //     setDetails(data);
  //     setPassport(data.photo);
  //       setStage(data.stage)
  //     let staging = data.stage;
  //     if (staging === null) {
  //       navigate("/apply/payment");
  //     } else if (staging === null){
  //       navigate("/apply/biodata");
  //     }else if (staging === "biodata") {
  //       navigate("/apply/biodata");
  //     } else if (staging === "nok") {
  //       navigate("/apply/education-details");
  //     } else if (staging === "educational") {
  //       navigate("/apply/preview");
  //     } else{
  //       navigate('/apply')
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  useEffect(() => {
    // getDetails();
    fetchPaymentStatus()
  }, []);

  useEffect(() => {
    $(menuRef.current).metisMenu();
  });

  if (token === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/login`,
          state: { from: location },
        }}
        replace
      />
    );
  }

  const handleLogout = () => {
    localStorage.clear();
    navigate("/staff/admissions/clearance/login");
  };

  const logOut = () => {
    removeUser();
    navigate("/apply/login");
  };

  function toggle() {
    let toggleAble = document.querySelector("body");
    toggleAble.classList.toggle("enlarge-menu");
  }

  function handleClose() {
    let toggleAble = document.querySelector("body");

  if (screenSize <= 1024) {
    toggleAble.classList.add("enlarge-menu-all");
    toggleAble.classList.add("enlarge-menu");
  }
}

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  return (
    <>
      <div class="leftbar-tab-menu">
        <div class="main-icon-menu">
          <a
            href="../analytics/analytics-index.html"
            class="logo logo-metrica d-block text-center"
          >
            <span>
              {/* <img src="../assets/images/CIWA_logo.png" alt="logo-small" className="logo-sm"/> */}
            </span>
          </a>
          <nav className="nav">
            <NavLink
              to="/apply"
              className="nav-link"
              data-toggle="tooltip-custom"
              data-placement="right"
              title=""
              data-original-title="Analytics"
              data-trigger="hover"
            >
              <Home className="align-self-center menu-icon icon-dual" />
            </NavLink>

            {/* <a href="#MetricaApps" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Apps" data-trigger="hover">
                        <i data-feather="grid" class="align-self-center menu-icon icon-dual"></i>
                    </a>

                    <a href="#MetricaUikit" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="UI Kit" data-trigger="hover">
                        <i data-feather="package" class="align-self-center menu-icon icon-dual"></i>
                    </a>

                    <a href="#MetricaPages" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Pages" data-trigger="hover">
                        <i data-feather="copy" class="align-self-center menu-icon icon-dual"></i>             
                    </a>

                    <a href="#MetricaAuthentication" class="nav-link" data-toggle="tooltip-custom" data-placement="right" title="" data-original-title="Authentication" data-trigger="hover">
                        <i data-feather="lock" class="align-self-center menu-icon icon-dual"></i>
                    </a> */}
          </nav>
          <div class="pro-metrica-end">
            <a
              href=""
              onClick={logOut}
              class="help"
              data-toggle="tooltip-custom"
              data-placement="right"
            >
              <LogOut class="align-self-center menu-icon icon-md icon-dual mb-4" />
            </a>
            <NavLink href="" class="profile">
              <img
                src={data?.photo}
                onError={onImageError}
                alt="profile-user"
                class="rounded-circle thumb-sm"
              />
            </NavLink>
          </div>
        </div>

        <div class="main-menu-inner">
          <div class="topbar-left">
            <a to="" class="logo">
              <span>
                <img
                  src="../assets/images/CIWA_logo.png"
                  alt="logo-large"
                  class="logo-sm logo-dark"
                  style={{
                    width: "35%",
                    height: "35%",
                    marginLeft: "2rem",
                    marginTop: "1rem",
                  }}
                />
              </span>
            </a>
          </div>
          <div class="menu-body slimscroll">
            <div class="title-box">
              <h6 class="menu-title">Dashboard</h6>
            </div>
            {data && 
            <ul ref={menuRef} className="nav metismenu">

                <>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/apply" onClick={handleClose} end>
                      Dashboard
                    </NavLink>
                  </li>
              {/* <li className="nav-item">
                <NavLink className="nav-link" to="/apply/payment">
                  Payment
                </NavLink>
              </li> */}
                  <li class="nav-item">
                      <a class="nav-link" href="#"><span class="w-100">Payment</span><span class="menu-arrow"><i class="mdi mdi-chevron-right"></i></span></a>
                      <ul class="nav-second-level" aria-expanded="false">
                          <li><NavLink to="/apply/payment" onClick={handleClose} end>Payment</NavLink></li>
                          <li><NavLink to="/apply/payment-invoice" onClick={handleClose}>Invoice</NavLink></li>
                      </ul>            
                  </li>
              {(stage === 'completed' || stage === "rejected" || stage === "approved") ? 
              "" : <>
                     <li className="nav-item">
                       <NavLink className="nav-link" to="/apply/biodata" onClick={handleClose}>
                         Biodata
                       </NavLink>
                     </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/apply/education-details" onClick={handleClose}>
                      Education Details
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/apply/upload-doc" onClick={handleClose}>
                      Document Upload
                    </NavLink>
                  </li>
                </>
                  }
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/apply/preview" onClick={handleClose}>
                      Preview
                    </NavLink>
                  </li>
                  </>
            </ul>
            }
          </div>
        </div>
      </div>

      <div class="topbar">
        <nav class="navbar-custom">
          <ul class="list-unstyled topbar-nav float-right mb-0">
            

            <li class="dropdown">
              <a
                class="nav-link dropdown-toggle waves-effect waves-light nav-user"
                data-toggle="dropdown"
                href="#"
                role="button"
                aria-haspopup="false"
                aria-expanded="false"
              >
                <img
                  src={data?.photo}
                  alt="user"
                  onError={onImageError}
                  class="rounded-circle"
                />
                <span class="ml-1 nav-user-name hidden-sm">
                  {data?.othername} <i class="mdi mdi-chevron-down"></i>{" "}
                </span>
              </a>
              <div class="dropdown-menu dropdown-menu-right">
                <a class="dropdown-item" href="#">
                  <i class="dripicons-user text-muted mr-2"></i> Profile
                </a>
                <a class="dropdown-item" href="#">
                  <i class="dripicons-wallet text-muted mr-2"></i> My Wallet
                </a>
                <a class="dropdown-item" href="#">
                  <i class="dripicons-gear text-muted mr-2"></i> Settings
                </a>
                <a class="dropdown-item" href="#">
                  <i class="dripicons-lock text-muted mr-2"></i> Lock screen
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" href="#">
                  <i class="dripicons-exit text-muted mr-2"></i> Logout
                </a>
              </div>
            </li>
            <li class="mr-2">
              <a
                href="#"
                class="nav-link"
                data-toggle="modal"
                data-animation="fade"
                data-target=".modal-rightbar"
              >
                <AlignRight class="align-self-center" />
              </a>
            </li>
          </ul>

          <ul class="list-unstyled topbar-nav mb-0">
            <li>
              <a href="../analytics/analytics-index.html">
                <span class="responsive-logo">
                  <img
                    src="../assets/images/CIWA_logo.png"
                    alt="logo-small"
                    class="logo-sm align-self-center"
                    height="34"
                  />
                </span>
              </a>
            </li>
            <li>
              <button class="button-menu-mobile nav-link waves-effect waves-light">
                <Menu onClick={toggle} class="align-self-center"></Menu>
              </button>
            </li>
            <li class="hide-phone app-search">
              <h4>The Catholic Institute of West Africa (CIWA) </h4>
            </li>
          </ul>
        </nav>
      </div>
      <Outlet />
    </>
  );
}

export default ApplicationWrapper;
