import { async } from "q";
import React, { useEffect, useState, useRef } from "react";
import QRCode from "react-qr-code";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  GetApplicantDetails,
  GetApplicantOlevel,
  GetPaymentStatus,
} from "../../../api/application";
import logo from "../../../assets/images/CIWA_logo.png";
import { getCurrentUser } from "../../../components/services/auth/authService";
import { useReactToPrint } from "react-to-print";
import api from "../../../api/api";
import { toast } from "react-toastify";
import useDetails from "../../../hooks/useDetails";
import avatar from "../../../assets/images/user.png";
import CustomModal from "../components/CustomModal";

const ApplicationPreview = () => {
  const user = getCurrentUser();
  const [passport, setPassport] = useState();
  const [olevel, setOlevel] = useState();
  const [olevel2, setOlevel2] = useState();
  const [details1, setDetails1] = useState()
  const [details2, setDetails2] = useState()
  const [loading, setLoading] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState("");
  const [admissionFee, setAdmissionFee] = useState();
  const [resultFee, setResultFee] = useState();
  const [openModal, setOpenModal] = useState(false);
  const location = useLocation();

  const { data: student, staging: stage } = useDetails();

  const fetchPaymentStatus = async () => {
    try {
      const { data } = await GetPaymentStatus(user.application_id);
      console.log(data, "statuss");
      setAdmissionFee(data.application_fee);
      setResultFee(data.result_checking_fee);
    } catch (error) {}
  };

  const navigate = useNavigate();

  const printPaymentReceipt = () => {
    window.print();
  };

  const componentRef = useRef();

  const pageStyle = `
  @page {
    size: landscape;
  }
`;
  const printAcceptanceLetter = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: "Application file",
    onAfterPrint: () => console.log("Application letter printed"),
    pageStyle: pageStyle,
  });

  // const getOlevel = async () => {
  //   try {
  //     const data = await GetApplicantOlevel(user.application_id);
  //     console.log("Olevel list", data.olevel);
  //     setOlevel(data.olevel);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getFirstSittingCourses = async () => {
    try {
      const response = api.get(`/admissions/applicant/olevel/${user?.application_id}/1`)
        .then(({data}) => {
          setOlevel(data.olevel)
          setDetails1(data)
          console.log(data)
        })
    } catch (error) {
      
    }
  }

  const getSecondSittingCourses = async () => {
    try {
      const response = api.get(`/admissions/applicant/olevel/${user?.application_id}/2`)
        .then(({data}) => {
          setOlevel2(data.olevel)
          setDetails2(data)
        })
      
    } catch (error) {
      
    }
  }

  const onImageError = (e) => {
    e.target.src = avatar;
  };

  const submitApplication = async () => {
    setLoading(true);
    try {
      const { data } = await api.put(
        `/admissions/submit/${user.application_id}`
      );
      console.log("Submission Response", data);
      toast.success("Application submitted");
      setLoading(false);
      window.location.reload();
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // getOlevel();
    getFirstSittingCourses()
    getSecondSittingCourses()
    fetchPaymentStatus();
  }, []);

  if (stage === null) {
    return (
      <Navigate
        to={{
          pathname: `/apply/payment`,
          state: { from: location },
        }}
        replace
      />
    );
  }
  return (
    <>
      {student && (
        <div
          className="receipt"
          style={{ marginTop: "3rem" }}
          ref={componentRef}
        >
          <div className="contents">
            <div className="receipt-header">
              <div className="school-title">
                <img src={logo} alt="DSP" className="logo" />
                <h3 className="name">Catholic Institute of West Africa </h3>
              </div>
              <h4 className="subheader">
                CIWA ADMISSION SCREENING EXERCISE {student?.entrySession} <br />
                <span>Application Record</span>
              </h4>
            </div>
            <div className="receipt-body">
              <div className="body-header">
                <div className="passport">
                  <img
                    src={student?.photo}
                    onError={onImageError}
                    alt="Passport"
                  />
                </div>
                <div className="user-details">
                  <div>
                    <h3 className="user-details-option">Application Number:</h3>
                    <h3 className="user-details-info">{student?.username}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Surname:</h3>
                    <h3 className="user-details-info">{student?.surname}</h3>
                  </div>
                  <div>
                    <h3 className="user-details-option">Othername(s):</h3>
                    <h3 className="user-details-info">{student?.othername}</h3>
                  </div>
                </div>
                <div className="qr-code">
                  {student && <QRCode value={student?.username} size={90} />}
                </div>
              </div>
              <hr />
              <div className="body-info">
                <div>
                  <p className="user-details-option">Date of Birth:</p>
                  <p className="user-details-info">
                    {student?.dob + "/" + student?.mob + "/" + student?.yob}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Gender:</p>
                  <p className="user-details-info">{student?.gender}</p>
                </div>
                <div>
                  <p className="user-details-option">Email Address:</p>
                  <p className="user-details-info"> {student?.email}</p>
                </div>
                <div>
                  <p className="user-details-option">Nationality:</p>
                  <p className="user-details-info">
                    {student?.country_origin}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">State/LGA:</p>
                  <p className="user-details-info">
                    {student?.state_origin}/{student?.lga_origin}
                  </p>
                </div>
                <hr style={{border: '1px solid gray'}}/>
                <h6>
                  <b>Programmes/Courses Desired</b>
                </h6>
                <div>
                  <p className="user-details-option">
                    1st Choice Course of Study:
                  </p>
                  <p className="user-details-info">
                    {student?.degreeChoice1}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">
                    2nd Choice Course of Study:
                  </p>
                  <p className="user-details-info">
                    {student?.degreeChoice2}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">
                    Programme Type:
                  </p>
                  <p className="user-details-info">
                    {student?.programmeType}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">
                    Study Mode:
                  </p>
                  <p className="user-details-info">
                    {student?.studyMode}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">
                    Entry Mode:
                  </p>
                  <p className="user-details-info">
                    {student?.entryMode}
                  </p>
                </div>
                <hr style={{border: '1px solid gray'}} />
                {student?.programmeType === 'ND' &&
                  <>
                    <h6>
                  <b>Jamb Information</b>
                </h6>
                <div>
                  <p className="user-details-option">Jamb Number:</p>
                  <p className="user-details-info">{student?.jambno}</p>
                </div>
                <div>
                  <p className="user-details-option">UTME Score:</p>
                  <p className="user-details-info">{student?.utme_score}</p>
                </div>
                <hr style={{border: '1px solid gray'}}/>
                  </>
                }
                {student?.programmeType === 'HND' && 
                  <>
                    <h6>
                  <b>Previous Institution</b>
                </h6>
                <div>
                  <p className="user-details-option">Name of Institution:</p>
                  <p className="user-details-info">{student?.ND_inistitution}</p>
                </div>
                <div>
                  <p className="user-details-option">Course of Study:</p>
                  <p className="user-details-info">{student?.ND_course}</p>
                </div>
                <div>
                  <p className="user-details-option">Grade Class:</p>
                  <p className="user-details-info">{student?.ND_Class}</p>
                </div>
                <hr style={{border: '1px solid gray'}}/>
                  </>
                }
                <h6>
                  <b>First sitting Results</b>
                </h6>
                <div>
                  <p className="user-details-option">Examination Name:</p>
                  <p className="user-details-info">
                    {details1?.exam_name}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Examination Reg Number:</p>
                  <p className="user-details-info">
                    {details1?.exam_reg_no}
                  </p>
                </div>
                <div>
                  <p className="user-details-option">Examination Date:</p>
                  <p className="user-details-info">
                    {details1?.exam_date}
                  </p>
                </div>
                <div>
                  <table>
                    <th>Subject</th>
                    <th>Grade</th>
                    <tbody>
                      {olevel?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.subject}</td>
                          <td>{item.grade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                {/* Second sitting*/}
                {olevel2?.length &&
                  <>
                <br />
                <h6>
                  <b>Second Sitting Results</b>
                </h6>
                <div>
                  <p className="user-details-option">Examination Name:</p>
                  <h3 className="user-details-info">
                    {details2?.exam_name}
                  </h3>
                </div>
                <div>
                  <p className="user-details-option">Examination Reg Number:</p>
                  <h3 className="user-details-info">
                    {details2?.exam_reg_no}
                  </h3>
                </div>
                <div>
                  <p className="user-details-option">Examination Date:</p>
                  <h3 className="user-details-info">
                    {details2?.exam_date}
                  </h3>
                </div>
                <div>
                  <table>
                    <th>Subject</th>
                    <th>Grade</th>
                    <tbody>
                      {olevel2?.map((item) => (
                        <tr key={item.id}>
                          <td>{item.subject}</td>
                          <td>{item.grade}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                  </>
                }
              </div>
            </div>

            <div className="print-button">
              {(stage === "completed" || stage === "rejected" || stage === "approved") ? (
                <button className="btn-primary" onClick={printAcceptanceLetter}>
                  Print Application
                </button>
              ) : (
                <>
                  <button
                    className="btn-primary"
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                  >
                    Submit Application
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
      )
      <CustomModal
        submitApplication={submitApplication}
        loading={loading}
        modalTitle="Are you sure?"
        modalBody="Make sure you have provided all necessary information before proceeding. You cannot access them after submission except you contact the school's Admin."
      />
    </>
  );
};

export default ApplicationPreview;
